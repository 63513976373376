@import "../../../styles/helpers";

.line {
    display: flex;
    padding-bottom: 16px;
    &:not(:last-child) {
        margin-bottom: 32px;
        border-bottom: 1px solid $n3;
        @include t {
            margin-bottom: 24px; }
        @include dark {
            border-color: $n6; } } }

.title {
    margin-right: 24px;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.switch {
    margin-left: auto; }
