@import "../../../styles/helpers";

.cardHead {
    @include m {
        display: block; } }

.cardTitle {
    @include m {
        margin-bottom: 16px; } }

.chart {
    width: auto;
    height: 244px;
    margin-left: -25px;
    margin-bottom: -10px; }

