@import "../../../styles/helpers";

.line {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n6; } }

.title {
    margin-right: 24px;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.button {
    margin-left: auto; }

.email {
    margin-bottom: 16px;
    @include base2; }

.content {
    @include caption2;
    font-weight: 500;
    color: $n4; }
