@import "../../../styles/helpers";

.item {
    position: relative; }

.anchor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }

.title {
    margin-bottom: 32px;
    line-height: 40px; }
