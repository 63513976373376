@import "../../styles/helpers";

.outer {
    padding: 24px;
    border-radius: 8px; }



 :global {
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        background: #3772FF;
        color: #fff; } }

.reasonList {
    list-style: unset;
    padding-left: 20px; }
