@import "../../../../../styles/helpers";

.products {
    margin-top: 64px;
    padding-top: 64px;
    border-top: 1px solid $n3;
    @include t {
        margin-top: 48px;
        padding-top: 48px; }
    @include m {
        margin-top: 32px;
        padding-top: 32px; }
    @include dark {
        border-color: $n6; } }

.head {
    display: flex;
    align-items: center;
    margin-bottom: 32px; }

.title {
    margin-right: auto; }

.wrapper {
    margin: 0 -12px;
    @include m {
        margin: 0; } }



