@import "../../styles/helpers";

.wrapper {
    margin: 0 -12px;
    @include t {
        margin: 0; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: none; } }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include caption1;
    color: $n4;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background: $n3;
        @include dark {
            background: $n6; } }
    &:first-child {
        width: 56px;
        font-size: 0;
        &:after {
            left: 12px; } }
    &:last-child {
        padding-right: 164px;
        &:after {
            right: 12px; } } }

.foot {
    margin-top: 20px;
    text-align: center;
    @include t {
        margin-top: 0; }
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
