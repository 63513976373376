@import "../../../styles/helpers";

.infoRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    &:last-of-type {
        margin-bottom: 0px; } }

.infoLeft {}

.infoRight {}

.title {
    color: $n5;
    @include base2;
    margin-bottom: 4px;
    @include dark {
        color: $n1; } }

.text {
    color: $n4;
    @include caption2;
    font-weight: 400; }

