@import "../../../styles/helpers";

.table {
    display: table;
    width: 100%;
    @include m {
        display: block; } }

.row {
    display: table-row;
    @include m {
        display: block; }
    &:first-child {
        @include m {
            display: none; }
        .col {
            padding-bottom: 24px;
            border-bottom: 1px solid $n3;
            white-space: nowrap;
            @include caption1;
            color: $n4;
            @include dark {
                border-color: $n6; } } }
    &:not(:first-child) {
        .col {
            padding-top: 24px;
            @include m {
                padding: 0; }
            &:first-child {
                @include base2;
                color: $n4; } } }
    &:nth-child(2) {
        .col {
            padding-top: 32px;
            @include m {
                padding-top: 0; } } }
    &:not(:last-child):not(:first-child) {
        @include m {
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid $n3;
            @include dark {
                border-color: $n6; } }
        .col {
            padding-bottom: 16px;
            border-bottom: 1px solid $n3;
            @include m {
                padding-bottom: 0;
                border: none; }
            @include dark {
                border-color: $n6; } } } }

.col {
    display: table-cell;
    vertical-align: middle;
    @include m {
        display: flex;
        align-items: center; }
    &:not(:last-child) {
        padding-right: 16px;
        @include m {
            margin-bottom: 8px;
            padding-right: 0; } } }

.label {
    display: none;
    @include m {
        display: block;
        flex-shrink: 0;
        width: 116px;
        padding-right: 12px;
        @include caption1;
        color: $n4; } }

.nav {
    display: none;
    margin-top: 32px;
    @include m {
        display: flex; } }

.link {
    flex: 1;
    padding: 8px 16px;
    border-radius: 8px;
    @include base1-s;
    color: $n4;
    transition: all .2s;
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $n1; } }
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } } }
