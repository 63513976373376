@import "../../../../styles/helpers";

.title {
    margin-bottom: 24px; }

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    flex: 0 0 50%;
    &:first-child {
        padding: 24px;
        border-radius: 12px;
        background: rgba($n2, .5);
        @include t {
            margin-bottom: 48px; }
        @include m {
            margin-bottom: 32px;
            padding: 16px; }
        @include dark {
            background: $n6; } }
    &:nth-child(2) {
        padding: 30px 0 0 24px;
        @include t {
            padding: 0; } } }

.product {
    margin-bottom: 16px; }

.btns {
    display: flex;
    margin: 24px -4px 0;
    .button {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 4px;
        @include m {
            padding: 0 8px; }
        &:first-child {
            @include dark {
                background: $n8;
                box-shadow: inset 0 0 0 2px $n8;
                &:hover {
                    box-shadow: inset 0 0 0 2px $n1; } } } } }

.box {
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba($shades4, .1); } }

.info {
    margin-bottom: 20px;
    @include title1-s; }

.list {
    li {
        position: relative;
        padding-left: 36px;
        font-weight: 500;
        color: $n5;
        @include dark {
            color: $n3; }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%23b5e4ca'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto; }
        &:not(:last-child) {
            margin-bottom: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid $n3;
            @include dark {
                border-color: $n6; } } } }

.text {
    margin-bottom: 12px;
    font-weight: 500;
    color: $n5;
    @include dark {
        color: $n3; } }

.user {
    display: flex;
    align-items: center; }

.avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.editor {
    margin-top: 32px;
    @include m {
        margin-top: 24px; } }
