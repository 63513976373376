@import "../../styles/helpers";

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px; }


/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }


/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E6E8EC;
    -webkit-transition: .4s;
    transition: .4s;
    @include dark {
        background-color: #272B30; }
    &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s; } }


input:checked + .slider {
  background-color: #3772FF; }



input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }


input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }



