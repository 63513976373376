@import "../../../../styles/helpers";

.box {
    display: flex;
    align-items: center; }

.line {
    position: relative;
    flex-shrink: 0;
    width: 44px;
    height: 12px;
    margin-left: 8px; }

.progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 2px; }

.counter {
    padding: 0 7px;
    border-radius: 6px;
    background: $n3;
    @include dark {
        background: $n6; } }

.balance {
    margin-top: 10px; }
