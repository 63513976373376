@import "../../../../styles/helpers";

.market {
    margin: 0 -12px;
    @include t {
        margin: 0; } }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: block;
        margin-bottom: 24px; } }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include caption1;
    color: $n4;
    @include t {
        display: block;
        padding: 0; }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background: $n3;
        @include dark {
            background: $n6; }
        @include t {
            display: none; } }
    &:first-child {
        width: 56px;
        font-size: 0;
        @include t {
            display: none; }
        &:after {
            left: 12px; } }
    &:nth-child(2) {
        width: 300px;
        @include t {
            display: none; } }
    &:last-child {
        &:after {
            right: 12px; } } }

.line {
    display: flex;
    align-items: center;
    @include t {
        display: block; } }

.info {
    margin-right: auto;
    @include t {
        display: none; } }

.indicators {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 0 -24px;
    @include w {
        margin-left: -12px; }
    @include t {
        margin-left: -24px; } }

.legend {
    position: relative;
    margin: 8px 0 0 24px;
    padding-left: 24px;
    @include w {
        margin-left: 12px; }
    @include d {
        padding-left: 18px; }
    @include t {
        margin-left: 24px;
        padding-left: 24px; } }

.color {
    position: absolute;
    top: 2px;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 4px; }

.foot {
    margin-top: 20px;
    text-align: center;
    @include t {
        margin-top: 0; } }
