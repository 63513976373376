@import "../../../styles/helpers";

.item {
    display: flex;
    align-items: center;
    color: $n5;
    @include dark {
        color: $n3; }
    &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $n3;
        @include dark {
            border-color: $n6; } } }

.flag {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 50%;
    background: $n3;
    @include dark {
        background: $n6; } }

.price {
    margin-left: auto;
    padding-left: 20px;
    color: $n7;
    @include dark {
        color: $n1; } }

