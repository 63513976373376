@import "../../../../../styles/helpers";

.item {
    display: flex;
    align-items: center; }

.preview {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.product {
    margin-bottom: 8px;
    @include title1-s; }

.category {
    @include caption1;
    color: $shades1; }

.new,
.progress {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 6px;
    white-space: nowrap; }

.new {
    background: $s4; }

.progress {
    background: $s2; }
