@import "../../../styles/helpers";

.nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    padding: 8px;
    background: $n1;
    border-radius: 20px;
    @include m {
        margin-bottom: 24px; }
    @include dark {
        background: $n7; } }

.overview {
    // display: flex
    flex-direction: column; }

.outerDiv {
    display: flex;
    width: 100%;
    border-radius: 20px;
    &:hover {
        background-color: $n3;
        @include dark {
            background-color: $n7; } } }
.cardInner {
    background: $n1;
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    h3 {
        font-size: 20px;
        font-weight: 600;
        color: #6d6d6d;
        margin-bottom: 5px; }
    p {
        color: #000; } }
