@import "../../styles/helpers";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap');

.row {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    @include t {
        display: block; } }

.col {
    margin-bottom: 24px;
    &:first-child {
        flex: 0 0 calc(100%);
        width: calc(100%);
        padding-right: 0px;
        @include x {
            flex: 0 0 calc(100%);
            width: calc(100%); }
        @include d {
            flex: 0 0 calc(100%);
            width: calc(100%); }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } } }


.card {
    margin-bottom: 8px;
    @include t {
        margin-bottom: 16px; } }

.reportWrap {
    width: 100%;
    display: flex;
    @include t {
        flex-direction: column;
        padding: 8px; } }

.leftInner {
    display: flex; }

.leftImg {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    overflow: hidden;
    img {
        object-fit: cover;
        height: 48px;
        width: 48px; } }

.leftInfo {
    padding-left: 16px;
    font-family: 'Poppins', sans-serif;

    h3 {
        color: $neutrals2;
        @include base2;
        font-weight: 500;
        width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include dark {
            color: $n1; } }
    p {
        color:  #777E91;
        @include body2-s;
        font-weight: 400;
        padding-top: 2px; } }

.reportRight {
    padding: 0 26px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #777E91;
    font-family: 'Poppins', sans-serif;
    word-break: break-word;
    @include t {
        padding: 34px 0 0; } }

.hideText {
    display: none; }

.readMore {
    color: #3772FF; }

.loadMoreWrap {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    @include t {
        margin-top: 16px; } }

.loadMoreBtn {
    width: auto;
    border: 2px solid $neutrals6;
    border-radius: 90px;
    height: 40px;
    color: $neutrals2;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    font-family: 'DM Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        border-color: #9a9fa5; }
    @include dark {
        color: $n1; } }



