@import "../../../styles/helpers";

.head {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid $n3;
    @include dark {
        border-color: $n6; } }

.info {
    margin-bottom: 20px;
    @include body2-s; }

.btns {
    display: flex;
    margin: 0 -4px;
    .button {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 4px;
        padding: 0 16px;
        img {
            margin-right: 10px; } } }

.body {
    .button {
        width: 100%; } }

.field {
    margin-bottom: 12px; }

.note {
    margin-top: 32px;
    @include body2-s;
    color: $shades1; }
