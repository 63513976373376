.actionModal {
  .modalContent {
    .topRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 0.1px solid #7c7c82;

      .preview {
        background: #545454;
        height: 60px;
        width: 60px;
        border-radius: 12px;

        img {
          height: 60px;
          width: 60px;
          border-radius: 12px;
          object-fit: cover; } } }

    .outer {
      border-radius: 10px;
      background: #f5f5f5;
      padding: 14px; }

    .labelTxt {
      margin-bottom: 5px;
      font-size: 14px; }

    .metaInfo {
      border-radius: 6px;
      border: 1px solid #ccc;
      padding: 10px;
      width: 100%;

      input {
        width: 100%;
        background-color: transparent;
        outline: 0;
        border: 0; } }

    .bluetxt {
      color: #0ea5e9; }

    .borde_btm {
      border-bottom: 0.1px solid #7c7c82; }

    .bluebtn {
      width: 100%;
      padding: 14px;
      border-radius: 10px;

      //   opacity: 0.3;
      background: #0ea5e9;
      backdrop-filter: blur(13px);
      border: 0;
      color: #fafafa;
      font-size: 16px;
      font-weight: 600; }

    .cencelbtn {
      border-radius: 10px;
      border: 1px solid #f11515;
      backdrop-filter: blur(13px);
      color: #f11515;
      font-size: 16px;
      font-weight: 600;
      background-color: #fff;
      opacity: 1; } }

  .txtarea {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ccc;
    outline: 0;
    padding: 10px; }

  .title {
    color: #000;
    font-size: 28px;
    font-weight: 700; }

  .desc {
    color: #777e90;
    text-align: center;
    font-size: 18px;
    font-weight: 500; }

   :global {
    .ant-modal-title {
      color: #000;
      font-size: 28px;
      font-weight: 700; }

    .ant-modal-body {
      padding: 20px; }

    .ant-picker {
      border: 0; } } }
