@import "../../styles/helpers";

.head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .button {
        @include m {
            margin-left: 16px; }
        span {
            margin-left: 4px;
            @include m {
                display: none; } } } }

.info {
    max-width: 240px;
    margin-right: auto;
    font-weight: 500;
    color: $n4;
    strong {
        font-weight: 600;
        color: $n7;
        @include dark {
            color: $n1; } } }

.list {
    display: flex; }

.item,
.all {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 32px 16px;
    flex: 0 0 25%;
    text-align: center;
    color: $n7;
    transition: color .2s;
    @include m {
        flex: 0 0 33.333%;
        padding: 16px 8px; }
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1; } }

.item {
    @include caption1-m;
    &:nth-child(n+3) {
        @include a {
            display: none; } } }

.all {
    @include caption1; }

.avatar,
.icon {
    width: 64px;
    height: 64px;
    margin: 0 auto 12px; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $n3;
    border-radius: 50%;
    background: $n3;
    transition: all .2s;
    svg {
        fill: $n4;
        transition: fill .2s; }
    @include dark {
        background: $n6;
        border-color: $n6; } }

.all {
    &:hover {
        .icon {
            border-color: $p1;
            background: none;
            svg {
                fill: $p1; } } } }
