@import "../../styles/helpers";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 112px 40px 56px;
  background: $n7;
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;
  @include t {
    padding: 112px 24px 56px; }
  @include m {
    padding: 148px 16px 32px; }
  @include dark {
    background: $n1; } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  max-width: 1124px;
  width: 100%;
  margin: auto; }

.video {
  video {
    width: 100%;
    border-radius: 12px; } }

.wrapper {
  overflow: hidden;
  padding: 0 56px;
  @include m {
    padding: 0; } }

.preview {
  display: flex;
  align-items: center;
  height: 100%;
  img {
    width: 100%;
    border-radius: 16px; } }

.figcaption {
  margin-top: 32px;
  text-align: center;
  @include caption1;
  color: $n4;
  @include d {
    margin-top: 24px; }
  @include m {
    margin-top: 16px; } }
