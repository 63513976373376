@import "../../../styles/helpers";

.wrapper {
    margin: 0 -24px;
    background: $n1;
    @include m {
        margin: 0 -16px; }
    @include dark {
        background: transparent; } }

.table {
    display: table;
    width: 100%; }

.row {
    display: table-row;
    &:first-child {
        .col {
            padding-top: 12px;
            padding-bottom: 16px;
            @include caption2;
            font-weight: 500;
            color: $n4; } }
    &:nth-child(2n) {
        .col {
            background: rgba($n3, .35);
            @include dark {
                background: rgba($n6, .5); } } } }

.col {
    display: table-cell;
    padding: 10px 16px;
    @include base2;
    @include m {
        padding: 12px; }
    &:first-child {
        padding-left: 24px;
        color: $n4;
        @include m {
            padding-left: 16px; } }
    &:last-child {
        padding-right: 24px;
        @include m {
            padding-right: 16px;
            text-align: right; } }
    &:nth-child(2),
    &:nth-child(3) {
        @include m {
            display: none; } } }

.status {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    color: $n7; }
