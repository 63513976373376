@import "../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 340px);
        width: calc(100% - 340px);
        padding-right: 8px;
        @include x {
            flex: 0 0 calc(100% - 312px);
            width: calc(100% - 312px); }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include x {
            width: 312px; }
        @include t {
            width: 100%; } } }

.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }
