@import "../../../styles/helpers";

.actions {
    margin-left: 16px; }

.actionsHead {
    @include dark {
        background: transparent; } }

.dropdown {
    min-width: 105px; }

.foot {
    margin-top: 32px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
