@import "../../../styles/helpers";

.row {
    display: table-row;
    transition: background .2s;
    @include t {
        position: relative;
        display: block;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $n3;
        @include dark {
            border-color: $n6; } }
    &:hover {
        @include rmin(1260) {
            background: $n2;
            @include dark {
                background: $n6; }
            .control {
                visibility: visible;
                opacity: 1; } } } }

.col {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding: 16px 12px;
    color: $n7;
    @include t {
        display: block;
        position: static;
        padding: 0; }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background: $n3;
        @include dark {
            background: $n6; }
        @include t {
            display: none; } }
    &:first-child {
        width: 56px;
        border-radius: 8px 0 0 8px;
        font-size: 0;
        @include t {
            display: none; }
        &:after {
            left: 12px; } }
    &:last-child {
        padding-right: 164px;
        border-radius: 0 8px 8px 0;
        color: $n4;
        @include t {
            padding: 0; }
        &:after {
            right: 12px; } }
    &:not(:nth-child(2)) {
        @include t {
            display: none; } } }

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;
    @include m {
        align-items: stretch; }
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1; }
    .price,
    .empty,
    .date {
        display: none; }
    .price,
    .empty {
        @include t {
            display: inline-block; } }
    .date {
        @include t {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            @include caption1;
            color: $shades1;
            svg {
                margin-right: 8px;
                fill: $shades1; } } } }

.preview {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;
    @include t {
        width: 104px;
        height: 116px; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.product {
    max-width: 170px;
    margin-bottom: 4px;
    font-weight: 700;
    @include t {
        max-width: 100%;
        margin-bottom: 8px;
        padding-right: 44px;
        @include title2; } }

.link {
    @include caption1;
    color: $shades1;
    @include t {
        display: none; } }

.price,
.empty {
    flex-shrink: 0;
    display: inline-block;
    padding: 0 8px;
    border-radius: 6px;
    line-height: 32px;
    font-weight: 700; }

.price {
    background: $s4;
    @include dark {
        color: $n7; } }

.empty {
    background: $n3;
    @include dark {
        background: $n6;
        color: $n1; } }

.control {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include d {
        visibility: visible;
        opacity: 1; }
    @include t {
        display: none; } }

.actions {
    display: none;
    @include t {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2; }
    .actionsHead {
        @include dark {
            background: none; } } }
