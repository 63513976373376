@import "../../../../styles/helpers";

.item {
    display: inline-flex;
    align-items: center; }

.counter {
    padding: 0 6px;
    border-radius: 6px;
    cursor: pointer;
    @include dark {
        color: $n7; }
    &:hover + .balance {
        visibility: visible;
        opacity: 1; } }

.balance {
    margin-left: 6px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include d {
        visibility: visible;
        opacity: 1; } }
