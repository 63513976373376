@import "../../../styles/helpers";

.list {
    display: flex;
    justify-content: space-between;
    @include t {
        display: block; } }

.item {
    display: block;
    padding: 0 32px 0 64px;
    color: $n7;
    @include x {
        padding: 0 32px 0 48px; }
    @include d {
        padding: 0 32px; }
    @include t {
        display: flex;
        padding: 0; }
    @include dark {
        color: $n1; }
    &:not(:first-child) {
        border-width: 0 0 0 1px;
        border-style: solid;
        border-color: $n3;
        @include t {
            margin-top: 16px;
            padding-top: 16px;
            border-width: 1px 0 0; }
        @include dark {
            border-color: $n6; } }
    &:first-child {
        padding-left: 0; }
    &:last-child {
        padding-right: 0; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
    border-radius: 50%;
    @include t {
        margin: 0 16px 0 0; }
    svg {
        fill: $n7; } }

.label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include caption1;
    color: $n4; }

.tooltip {
    svg {
        fill: $n4; } }

.counter {
    min-width: 238px;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.03em;
    @include t {
        min-width: auto;
        font-size: 40px; }
    @include m {
        font-size: 32px; } }
