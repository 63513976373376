@import "../../styles/helpers";

.head {
    margin-bottom: 44px;
    @include t {
        margin-bottom: 32px; }
    @include m {
        flex-wrap: wrap;
        margin-bottom: 24px; }
    .form {
        @include m {
            flex: 0 0 100%;
            margin-top: 16px; } } }

.title {
    margin-right: 24px; }

.foot {
    margin-top: 44px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
