@import "../../styles/helpers";

.row {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    @include t {
        display: block; } }

.col {
    margin-bottom: 24px;
    &:first-child {
        flex: 0 0 calc(100%);
        width: calc(100%);
        padding-right: 0px;
        @include x {
            flex: 0 0 calc(100%);
            width: calc(100%); }
        @include d {
            flex: 0 0 calc(100%);
            width: calc(100%); }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } } }


.card {
    margin-bottom: 24px;
    &:not(:last-child) {
        margin-bottom: 8px; } }
