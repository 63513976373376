@import "../../../styles/helpers";

.cardHead {
    .button {
        @include m {
            display: none; } } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include m {
        display: block;
        margin: 0; } }

.product {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 32px 16px 0;
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 24px; } } }

.info {
    margin: 32px 0 16px;
    padding-top: 32px;
    border-top: 1px solid $n3;
    font-weight: 500;
    color: $n4;
    @include m {
        margin-top: 24px;
        padding-top: 24px; }
    @include dark {
        border-color: $n6; } }

.btns {
    display: flex;
    margin: 0 -8px;
    .button {
        flex: 0 0 calc(33.333% - 16px);
        width: calc(33.333% - 16px);
        margin: 0 8px;
        @include m {
            font-size: 0;
            svg {
                &:not(:last-child) {
                    margin-right: 0; } } } } }
