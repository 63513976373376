@import "../../../styles/helpers";

.item {
    @include d {
        position: relative; }
    @include m {
        position: static; }
    &.active {
        .head {
            border-color: $p1;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
            svg {
                fill: $n7;
                @include dark {
                    fill: $n1; } } }
        .body {
            visibility: visible;
            opacity: 1; } } }

.head {
    display: flex;
    min-height: 68px;
    padding: 14px;
    border-radius: 12px;
    border: 2px solid $n3;
    cursor: pointer;
    transition: all .2s;
    svg {
        flex-shrink: 0;
        margin-right: 12px;
        fill: $n4;
        transition: fill .2s; }
    @include dark {
        border-color: $n6; }
    &:hover {
        border-color: $p1; } }

.category {
    @include caption2-m;
    color: $n4; }

.body {
    position: absolute;
    top: 0;
    left: calc(100% + 8px);
    background: $n1;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include d {
        top: 100%;
        left: 0;
        right: 0;
        z-index: 3; }
    @include m {
        top: 50%;
        left: 12px;
        right: 12px;
        transform: translateY(-50%); }
    @include dark {
        background: $n7;
        @include m {
            box-shadow: inset 0 0 0 1px $n6; } } }


