@import "../../styles/helpers";

.background {
    height: 400px;
    margin: -40px -40px 0;
    @include x {
        margin: -32px -24px 0; }
    @include d {
        height: 320px; }
    @include m {
        height: 252px;
        margin: -24px -16px 0; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.card {
    max-width: 1200px;
    margin: -64px auto 0;
    @include m {
        margin-top: -188px; } }

.control {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    @include m {
        flex-wrap: wrap; } }

.nav {
    display: flex;
    margin-right: auto;
    @include m {
        width: 100%;
        margin: 0 0 16px; } }

.link {
    padding: 8px 16px;
    border-radius: 8px;
    @include base1-s;
    color: $n4;
    transition: all .2s;
    @include m {
        flex-grow: 1; }
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $n1; } }
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px;
        @include m {
            margin-right: 0; } } }

.dropdownBox {
    @include m {
        flex-grow: 1; } }

.filters {
    margin-left: 16px;
    @include m {
        margin-left: 12px; } }

.products {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -12px 0;
    @include a {
        display: block;
        margin: 0; } }

.product {
    flex: 0 0 calc(33.333% - 24px);
    width: calc(33.333% - 24px);
    margin: 32px 12px 0;
    @include t {
        flex: 0 0 calc(50% - 24px);
        width: calc(50% - 24px); }
    @include a {
        width: 100%;
        margin: 24px 0 0; } }

.foot {
    margin-top: 40px;
    text-align: center;
    @include x {
        margin-top: 32px; }
    @include m {
        margin-top: 24px; } }
