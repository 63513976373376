@import "../../../styles/helpers";

.chart {
    width: auto;
    height: 230px;
    @include x {
        margin-left: -12px; }
    @include t {
        max-width: 310px;
        margin: 0 auto; }
    @include m {
        padding-left: 10px; } }

.legend {
    display: flex;
    justify-content: space-between;
    margin-top: 32px; }

.indicator {
    display: flex;
    align-items: center;
    @include caption2;
    font-weight: 700; }

.color {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 4px; }
