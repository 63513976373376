@import "../../../styles/helpers";

.panel {
    @include t {
        display: none; } }

.info {
    margin-right: auto;
    @include caption1;
    color: $n4;
    svg {
        margin-right: 12px;
        fill: $n4; } }

.btns {
    display: flex;
    align-items: center; }

.button {
    &:not(:last-child) {
        margin-right: 8px; } }
