@import "../../../styles/helpers";

.details {
    position: relative;
    flex-grow: 1;
    padding: 60px 0 0 48px;
    @include w {
        padding: 48px 0 0 48px; }
    @include t {
        padding: 0; } }

.close {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    @include t {
        top: -90px;
        background: $n1;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); }
    @include m {
        top: -70px; }
    svg {
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $n1; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.head,
.editor {
    margin-bottom: 48px;
    @include x {
        margin-bottom: 32px; } }

.line {
    display: flex;
    align-items: center;
    @include base2;
    color: $n7;
    transition: color .2s;
    svg {
        margin-right: 12px;
        fill: $n4;
        transition: fill .2s; }
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1;
        svg {
            fill: $p1; } } }

.socials {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $n3;
    @include m {
        padding: 16px 0; }
    @include dark {
        border-color: $n6; } }

.social {
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } }
    &:not(:last-child) {
        margin-right: 32px; } }

.line,
.socials {
    &:not(:last-child) {
        margin-bottom: 20px;
        @include m {
            margin-bottom: 16px; } } }

.history {
    margin-top: 48px;
    @include x {
        margin-top: 32px; } }
