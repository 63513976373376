@import "../../../styles/helpers";

.wrapper {
    margin: 0 -12px;
    @include t {
        margin: 0; } }

.row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 12px 16px;
    @include caption1;
    color: $n4;
    @include t {
        display: none; }
    &:after {
        content: "";
        position: absolute;
        left: 12px;
        bottom: 0;
        right: 12px;
        height: 1px;
        background: $n3;
        @include dark {
            background: $n6; } } }

.col {
    &:first-child {
        flex-shrink: 0;
        width: 24px;
        font-size: 0; }
    &:nth-child(2) {
        flex-grow: 1;
        padding: 0 32px; }
    &:last-child {
        flex-shrink: 0;
        width: 268px; } }

.foot {
    margin-top: 24px;
    text-align: center;
    @include t {
        margin-top: 0; }
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
