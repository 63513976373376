
.analyticscard {
    background: #FCFCFC;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 40px;
    h3 {
        font-weight: 600;
        margin-bottom: 20px; }
    .selects {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px; } }
