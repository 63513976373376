@import "../../../styles/helpers";

.users {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    @include t {
        margin-right: 0; } }

.nav {
    display: flex;
    margin-bottom: 36px;
    padding: 4px;
    border-radius: 20px;
    background: $n2;
    @include d {
        margin-bottom: 24px; }
    @include m {
        margin-bottom: 16px; }
    @include dark {
        background: $n8; }
    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 50%;
        height: 32px;
        border-radius: 16px;
        @include base1-s;
        color: $n4;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        transition: color .2s;
        svg {
            margin-right: 8px;
            fill: $n4;
            transition: fill .2s; }
        &:hover,
        &.active {
            color: $n7;
            svg {
                fill: $n7; }
            @include dark {
                color: $n1;
                svg {
                    fill: $n1; } } }
        &.active {
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            background: $n1;
            @include dark {
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.49), inset 0px 2px 1px rgba(255, 255, 255, 0.06);
                background: $n6; } } } }

.list {
    max-height: 100%;
    margin: 0 -12px 16px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @include m {
        margin: 0 -8px 16px; } }

.form {
    width: 100%;
    margin-top: auto; }
