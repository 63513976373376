@import "../../../../styles/helpers";

.message {
    display: flex;
    &:not(:last-child) {
        margin-bottom: 32px; } }

.avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }

.details {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
    padding-left: 16px; }

.head {
    display: flex;
    align-items: center;
    margin-bottom: 4px; }

.man {
    color: $n4; }

.time {
    margin-left: 12px;
    @include caption1;
    color: $shades1; }

.content {
    font-weight: 500;
    a {
        font-weight: 600;
        color: $p1; } }
