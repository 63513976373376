@import "../../styles/helpers";

.smile {
    position: relative;
    &.active {
        .head {
            svg {
                fill: $n7;
                @include dark {
                    fill: $n1; } } }
        .body {
            visibility: visible;
            opacity: 1; } } }

.head {
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }

.body {
    position: absolute;
    width: 264px;
    padding: 4px;
    border-radius: 8px;
    background: $n;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include m {
        max-width: 168px; }
    @include dark {
        background: $n7;
        box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.3); }
    &.right {
        top: 50%;
        left: calc(100% + 10px);
        transform: translateY(-50%);
        @include m {
            top: auto;
            left: 50%;
            bottom: calc(100% + 10px);
            transform: translate(-50%, 0); } }
    &.up {
        left: 50%;
        bottom: calc(100% + 10px);
        transform: translateX(-50%); } }

.list {
    display: flex;
    flex-wrap: wrap; }

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: background .2s;
    img {
        width: 100%; }
    &:hover {
        background: $n3;
        @include dark {
            background: $n6; } } }
