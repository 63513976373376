@import "../../../../styles/helpers";

.label {
    margin-bottom: 14px;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1;
    svg {
        fill: $shades1; } }

.inner {
    margin: 0 -12px;
    @include m {
        margin: 0; } }

.table {
    display: table;
    width: 100%;
    @include m {
        display: block; } }

.row {
    display: table-row;
    cursor: pointer;
    transition: background .2s;
    @include m {
        display: block;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $n3;
        @include dark {
            border-color: $n6; } }
    &:first-child {
        @include m {
            display: none; }
        .col {
            padding-top: 0;
            padding-bottom: 24px;
            @include caption2;
            font-weight: 500;
            color: $n4; } }
    &:not(:first-child) {
        &:hover {
            @include rmin(768) {
                background: $n2;
                @include dark {
                    background: $n6; } } } } }

.col {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding: 12px;
    color: $n7;
    @include m {
        display: block;
        padding: 0; }
    @include dark {
        color: $n1; }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background: $n3;
        @include m {
            display: none; }
        @include dark {
            background: $n6; } }
    &:first-child {
        border-radius: 8px 0 0 8px;
        &:after {
            left: 12px; } }
    &:not(:first-child) {
        @include m {
            display: none; } }
    &:last-child {
        border-radius: 0 8px 8px 0;
        &:after {
            right: 12px; } } }

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    .price {
        display: none;
        @include m {
            display: inline-block;
            margin-top: 8px; } } }

.preview {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin-right: 16px;
    border-radius: 8px;
    overflow: hidden;
    @include m {
        width: 104px;
        height: 112px;
        margin-right: 20px; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.product {
    max-width: 200px;
    margin-bottom: 4px;
    @include m {
        max-width: 100%; } }

.link {
    @include caption1;
    color: $n4; }

.price {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 6px;
    background: $s4;
    @include base2;
    @include dark {
        color: $n7; } }

.date {
    @include base2;
    color: $n4; }

.foot {
    margin-top: 20px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
