@import "../../../styles/helpers";

.fieldset {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include t {
                margin-bottom: 24px; } } }
    .fieldabc {
        width: 100%;
        height: 48px;
        padding: 0 10px;
        border-radius: 12px;
        border: 2px solid transparent;
        background: $n2;
        @include inter;
        @include base1-s;
        color: $n7;
        transition: all .2s;
        @include dark {
            border-color: $n6;
            background: $n6;
            color: $n1; }
        @include placeholder {
            color: $n4;
            @include dark {
                color: $shades1; } }
        &:focus {
            border-color: $shades1;
            background: $n;
            @include dark {
                border-color: $n5;
                background: $n8; } } } }
.row {
    display: flex;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.btnSuccess {
    background: #198754 !important; }



.code {
    display: flex;
    justify-content: center;
    align-items: center; }

.txt {
    color: $neutrals1; }
