@import "../../../styles/helpers";

.ServiceFeeDescriptionVisible {
    color: $n4;
    padding: 2px; }

.ServiceFeeDescriptionNotVisible {
    color: $n4;
    padding: 2px;
    @include t {
        display: none; } }

.field {
    width: 50%; }

.input {
    display: flex; }

.btns {
    display: flex; }

.buttonVisible {
    width: 50%; }

.buttonNotVisible {
    width: 50%;
    @include t {
        display: none; }
    @include m {
        display: block; } }

.item,
.dropdown {
    margin-bottom: 8px; }

.item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    color: $n4;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s, box-shadow .2s, color .2s;
    @include d {
        width: 48px;
        font-size: 0; }
    @include m {
        width: 100%;
        font-size: 15px; }
    svg {
        margin-right: 12px;
        fill: $n4;
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 12px; } }
    &:hover,
    &.active {
        color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            color: $n1;
            svg {
                fill: $n1; } } }
    &.active {
        background: $p6;
        color: $n1;
        box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
        .icon {
            display: none; }
        .iconActive {
            display: block; }
        @include dark {
            background: $n6;
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11); } } }

.icon {
    padding-right: 12px; }

.iconActive {
    padding-right: 12px;
    display: none; }
