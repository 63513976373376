@import "../../styles/helpers";

.button {
    position: relative;
    width: 20px;
    height: 20px;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: all .2s;
        &:nth-child(2) {
            z-index: 2;
            opacity: 0; } }
    &:hover {
        svg {
            fill: $p1; } }
    &.active {
        svg {
            fill: $p1;
            &:first-child {
                opacity: 0; }
            &:nth-child(2) {
                opacity: 1; } } } }

