@import "../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100%);
        width: calc(100%);
        padding-right: 0px;
        @include x {
            flex: 0 0 calc(100%);
            width: calc(100%); }
        @include d {
            flex: 0 0 calc(100%);
            width: calc(100%); }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include x {
            width: 324px; }
        @include d {
            width: 312px; }
        @include t {
            width: 100%; } } }

.card {
    position: relative;
    padding: 24px;
    background: #FCFCFC;
    border-radius: 8px;
    &:not(:last-child) {
        margin-bottom: 8px; } }
