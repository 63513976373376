@import '../../styles/helpers.sass';
.searchMain {
    .search {
      height: 50px;
      width: 100%;
      border-radius: 12px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background: $n;
      position: relative;
      display: flex;
      align-items: center;
      padding: 5px 5px 5px 10px;
      input {
        width: 100%;
        border: none;
        outline: 0;
      }
      ::placeholder {
        color: #000;
      }
      .blueBtn {
        border-radius: 10px;
        -webkit-backdrop-filter: blur(13px);
        backdrop-filter: blur(13px);
        padding: 10px 22px;
        border: none;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        width: 100px;
        background-color: #0ea5e9;
      }
    }
    :global {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fff;
        border-color: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
    }
  }